body {}

html {
  overflow-x: hidden !important;
}



/* Navbar */
.brand-name {
  font-family: 'Lilita One', cursive !important;
  text-align: center;
  font-weight: bold;
  color: blanchedalmond !important;
  color: black !important;
  font-size: 2rem !important;
}

.navbar-main {

  background-color: rgb(32, 167, 190, 0.5) !important;

  ;
  /* padding: 1rem !important; */

}

.mobile-toggle-nav-btn {
  border: none !important;
}

.mobile-toggle-nav-btn:focus {
  border: none !important;
  box-shadow: none !important;
}

.nav-links {
  margin: 1rem !important;
  color: black;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}


.nav-links:hover {
  color: rgb(181, 241, 252);

}


/* carousel */

.carousel {
  font-family: 'Montserrat', sans-serif;
}

.carou-img {

  width: 100vw !important;
  height: 80vh;


}


.carousel-caption {
  background-color: #20A7BE !important;

  border-radius: 20px;
}


/* Products */

.tabs {}

.tabslist {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}



.custom-tabs-container {
  border-bottom: 1px solid #ccc;

}

.custom-tabs-header {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  border-bottom: none;

}

.custom-tab-item {
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  list-style-type: none;
}

.custom-tab-item:hover {
  border-bottom-color: #ccc;
}

.custom-tab-item.react-tabs__tab--selected {
  border-bottom-color: #20A7BE;
}


.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}

.each-card {

  margin: 1rem;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2) !important;
  font-family: 'Montserrat', sans-serif;
  border: 3px solid #20A7BE !important;


}

.each-card:hover {
  scale: 1.01;
  transition: 1s;
}



.each-card {
  height: 20rem;
  width: 20rem;
}


.card-img {

  /* object-fit: calc() !important; */
  height: 15rem !important;


}

.card-text {
  font-family: 'Montserrat', sans-serif;
}

.heading {
  text-align: center;
  margin: 1rem;
  color: #20A7BE;

}

.card-body {
  display: flex;
  flex-direction: column;

}

.card-title {
  text-align: center;
}

.products-get-in-touch {

  background-color: rgb(31, 167, 190) !important;
  border: none !important;
  display: inline-block;
  margin: 0 auto;

}

.products-get-in-touch:hover {
  transition: 0.5s;

  scale: 1.1;
}








.my-html-container-class button {
  border: none;
  padding: 10px;
  text-decoration: none;
  color: white;
  border-radius: 50px !important;
  background-color: #20A7BE;
  margin: 4px;
  align-self: center !important;



}

.my-html-container-class a {
  padding: 30px;
  text-decoration: none;
  color: white;
  text-align: center !important;

}

.my-html-container-class:hover a {
  color: white;
  transition: 0.5s;
  scale: 1.1;
}


.my-confirm-button-class {

  padding: 0.2rem !important;
}

.ng-cat-div {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
}

.ng,
.cat {
  overflow: visible;
  height: 8.5rem !important;
  width: 4rem !important;

}





/* About */


.about-us-div {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  padding-right: 1rem;


}


.about-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-image-div {
  width: 90%;
  max-width: 400px;
}

.video {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-div-text {
  width: 50%;
  max-width: 600px;
  padding: 0 2rem;
  font-size: 0.8rem !important;
  text-align: justify;

}



/* Footer */

.footer-div {
  display: flex;
  justify-content: center;
  align-items: center;



}

.get-heading {
  /* color: #20A7BE */

}

.footer-img {
  width: 45rem;
}



.footer-links {
  background-color: #20A7BE;
  color: white !important;
  padding: 1rem !important;
  border-radius: 1rem !important;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  z-index: 9999 !important;

}




@media only screen and (max-width:800px) {

  .collapsed-items {
    text-align: center !important;
  }


  .last-3-cards,
  .first-3-cards {
    flex-direction: column;

  }

  .carou-img {
    height: unset
  }

  h1 {
    font-size: 1rem !important;
  }

  .brand-name {
    font-size: 1rem !important;
  }

  .navbar-main {
    background-color: rgba(41, 121, 133, 0.9) !important;

    /* padding: 1rem !important; */

  }

  .about-row {
    display: flex;
  }

  .about-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }


  .footer-div {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .get-heading,
  .social-media {
    text-align: center;
  }




  .video {
    margin-left: unset;
  }







  /* .card-img {

    padding: unset !important;
    width: 100% !important;
    height: auto !important;

  } */




  .about-div-text {
    padding: 1rem;
    color: #20A7BE !important;
    width: 100%;
  }

  .about-image-div {
    width: 80%;

  }




  .heading {
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }




}